import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Project} from '../../classes/project.class';
import {RealisationService} from '../../services/realisation.service';
import {CodaltComponent} from '../../codalt.component';
import {zip} from 'rxjs';
import {ApiResponse} from '../../services/api/api.service';
import {Realisation, RealisationHourtype} from '../../classes/realisation';
import {Entity} from '../../classes/entity.class';
import {FormControl} from '@angular/forms';
import {Utils} from '../../utils.class';

@Component({
    selector: 'app-copy-realisation-from-other-day',
    templateUrl: './copy-realisation-from-other-day.component.html',
    styleUrls: ['./copy-realisation-from-other-day.component.scss']
})
export class CopyRealisationFromOtherDayComponent extends CodaltComponent implements OnInit {

    fcEntities = new FormControl();

    realisationList: PreviousUsedEntity[];

    constructor(public dialogRef: MatDialogRef<CopyRealisationFromOtherDayComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    project: Project,
                    date: Date,
                    realisations: Realisation[]
                },
                private realisationService: RealisationService) {
        super();
    }

    ngOnInit(): void {

        const realisationCalls$ = [];
        for (let i = 1; i <= 3; i++) {
            const date = new Date(this.data.date);
            date.setDate(date.getDate() - i);
            realisationCalls$.push(this.realisationService.getDaySchedule(date));
        }
        const currentEntityIds = this.data.realisations.filter(r => !r.planning_has_entity_id && !!r.entity_id).map(r => r.entity_id);
        this.subscriptions.add(zip(...realisationCalls$).subscribe(zipped => {
            const realisationList = [];
            zipped.forEach((realisations: ApiResponse<Realisation[]>) => {
                realisationList.push(...realisations.data.filter(r =>
                    !currentEntityIds.includes(r.entity_id) &&
                    !r.planning_has_entity_id &&
                    !!r.entity_id &&
                    r.project.afas_project_id === this.data.project.afas_project_id));
            });

            const entities = [] as PreviousUsedEntity[];
            realisationList.forEach(realisation => {
                const entity = entities.find(e => e.entity.id === realisation.entity_id);
                if (entity) {
                    entity.realisations.push(realisation);
                } else {
                    entities.push({
                        entity: realisation.entity,
                        realisations: [realisation]
                    });
                }
            });
            this.realisationList = entities;
        }));

    }

    take() {
        const saveCalls$ = [];
        if (this.fcEntities.value?.length) {
            this.fcEntities.value.forEach((entityId: number) => {
                const realisation = new Realisation();
                realisation.entity_id = entityId;
                realisation.afas_project_id = this.data.project.afas_project_id;
                realisation.hourtype = RealisationHourtype.worktime;
                realisation.bookdate = this.data.date;
                realisation.begindate = Utils.setTimeNewDate(this.data.date, 7, 0);
                realisation.enddate = Utils.addMinutes(realisation.begindate, (60 * 8));
                saveCalls$.push(this.realisationService.saveRealisation(realisation));
            });
            this.subscriptions.add(zip(...saveCalls$).subscribe((realisations: ApiResponse<{
                realisation: Realisation
            }>[]) => {
                this.dialogRef.close(realisations[0].data.realisation);
            }));
        } else {
            this.dialogRef.close();
        }
    }

}

export class PreviousUsedEntity {
    realisations: Realisation[];
    entity: Entity;
}
