import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SettingsMenuComponent} from './settings-menu/settings-menu.component';
import {RequestRestorePasswordComponent} from './auth/request-restore-password/request-restore-password.component';
import {RestorePasswordComponent} from './auth/restore-password/restore-password.component';
import {UserEditComponent} from './users/user-edit/user-edit.component';
import {UsersOverviewComponent} from './users/users-overview/users-overview.component';
import {ConfirmDialogComponent} from './services/confirm-dialog-service/confirm-dialog/confirm-dialog.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {UserService} from './services/user/user.service';
import {AuthService} from './services/auth/auth.service';
import {ApiService} from './services/api/api.service';
import {DatePipe, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {WebsocketService} from './services/websocket/websocket.service';
import {EntitiesOverviewComponent} from './entities-overview/entities-overview.component';
import {EntityDetailComponent} from './entities-overview/entity-detail/entity-detail.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDividerModule} from '@angular/material/divider';
import {ConfirmDialogService} from './services/confirm-dialog-service/confirm-dialog.service';
import {PlanningOverviewComponent} from './planning-overview/planning-overview.component';
import {PlanningDetailDialogComponent} from './planning-overview/planning-detail/planning-detail-dialog.component';
import localeNL from '@angular/common/locales/nl';
import {MatRadioModule} from '@angular/material/radio';
import {CutterListComponent} from './planning-overview/planning-detail/cutter-list/cutter-list.component';
import {WipetruckListComponent} from './planning-overview/planning-detail/wipetruck-list/wipetruck-list.component';
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions, MatTooltipModule} from '@angular/material/tooltip';
import {DecimalInputDirective} from './directives/decimal-input.directive';
import {HeaderComponent} from './header/header.component';
import {AsphaltListComponent} from './planning-overview/planning-detail/asphalt-list/asphalt-list.component';
import {CdkDropList, DragDropModule} from '@angular/cdk/drag-drop';
import {AsphaltComponent} from './asphalt/asphalt.component';
import {ImportAsphaltListDialogComponent} from './asphalt/import-asphalt-list-dialog/import-asphalt-list-dialog.component';
import {AsphaltService} from './services/asphalt/asphalt.service';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {DayComponent} from './planning-overview/day/day.component';
import {EntityComponent} from './planning-overview/day/entity/entity.component';
import {TrucksComponent} from './planning-overview/day/trucks/trucks.component';
import {TonsComponent} from './planning-overview/day/tons/tons.component';
import {DateIndicatorComponent} from './planning-overview/day/date-indicator/date-indicator.component';
import {AsphaltOrderListComponent} from './asphalt-order-list/asphalt-order-list.component';
import {SetPlanningFixedDialogComponent} from './planning-overview/set-planning-fixed-dialog/set-planning-fixed-dialog.component';
import {AsphaltExportDialogComponent} from './asphalt-export-dialog/asphalt-export-dialog.component';
import {PlanningListComponent} from './planning-list/planning-list.component';
import {PlanningDetailDialogService} from './services/dialog/planning-detail-dialog.service';
import {DialogService} from './services/dialog/dialog.service';
import {FieldErrorComponent} from './field-error/field-error.component';
import {TooltipPipe} from './tooltip.pipe';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {AsphaltListItemComponent} from './planning-overview/planning-detail/asphalt-list/asphalt-list-item/asphalt-list-item.component';
import {PlanningExportDialogComponent} from './planning-export-dialog/planning-export-dialog.component';
import {LoadingDialogComponent} from './services/dialog/loading-dialog/loading-dialog.component';
import {PlanningDetailContainerComponent} from './planning-overview/planning-detail-container/planning-detail-container.component';
import {MatIconModule} from '@angular/material/icon';
import {PlanningDetailPrintComponent} from './planning-detail-print/planning-detail-print.component';
import {ByIdPipe} from './by-id.pipe';
import {Nl2brPipe} from './nl2br.pipe';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {HirePeopleSelectorDialogComponent} from './planning-overview/planning-detail/hire-people-selector-dialog/hire-people-selector-dialog.component';
import {MatListModule} from '@angular/material/list';
import {PlanningEntitiesToStringPipe} from './planning-entities-to-string.pipe';
import {HirePeopleDialogComponent} from './hire-people-dialog/hire-people-dialog.component';
import {HirePeopleWeekComponent} from './hire-people-dialog/hire-people-week/hire-people-week.component';
import {ProjectmanagerReportDialogComponent} from './projectmanager-report-dialog/projectmanager-report-dialog.component';
import {HiringCompaniesDialogComponent} from './hiring-companies-dialog/hiring-companies-dialog.component';
import {HiringCompanyEditDialogComponent} from './hiring-companies-dialog/hiring-company-edit-dialog/hiring-company-edit-dialog.component';
import {TransportPlanningComponent} from './transport-planning/transport-planning.component';
import {MainPlanningPipe} from './main-planning.pipe';
import {IsNightPipe} from './is-night.pipe';
import {MaterialPiecePipe} from './material-piece.pipe';
import {NgSelectModule} from '@ng-select/ng-select';
import {IsSubsequentPipe} from './is-subsequent.pipe';
import {CodaltInterceptor} from './codalt.interceptor';
import {ProfileComponent} from './profile/profile.component';
import {EmployeesDialogComponent} from './transport-planning/employees-dialog/employees-dialog.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DatetimeInputComponent} from './datetime-input.component';
import {WhereInPipe} from './pipes/where-in.pipe';
import {HourRegistrationComponent} from './hour-registration/hour-registration.component';
import {HourEditDialogComponent} from './hour-registration/hour-edit-dialog/hour-edit-dialog.component';
import {MatMenuModule} from '@angular/material/menu';
import {TransportplanningColsPipe} from './transportplanning-cols.pipe';
import {WeekScheduleComponent} from './week-schedule/week-schedule.component';
import {DayDetailComponent} from './week-schedule/day-detail/day-detail.component';
import {CheckHoursComponent} from './check-hours/check-hours.component';
import {MinutesPipe} from './pipes/minutes.pipe';
import {ProjectSelectionDialogComponent} from './hour-registration/project-selection-dialog/project-selection-dialog.component';
import {PeriodSettlementComponent} from './period-settlement/period-settlement.component';
import {HiredCompaniesDialogComponent} from './hired-companies-dialog/hired-companies-dialog.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {AddressDialogComponent} from './transport-planning/employees-dialog/address-dialog/address-dialog.component';
import {LocationService} from './services/location.service';
import {AddressIconComponent} from './transport-planning/employees-dialog/address-icon/address-icon.component';
import {EquipmentDialogComponent} from './transport-planning/equipment-dialog/equipment-dialog.component';
import {EntityTypePipe} from './pipes/entity-type.pipe';
import {EntityUnavailablePipe} from './pipes/entity-unavailable.pipe';
import {CodaltComponent} from './codalt.component';
import {MultiplePipe} from './multiple.pipe';
import {AllApprovedPipe} from './check-hours/all-approved.pipe';
import {HasUserGroupPipe} from './pipes/has-user-group.pipe';
import {CommentDialogComponent} from './hour-registration/comment-dialog/comment-dialog.component';
import {OwnHourEditAllowedPipe} from './hour-registration/hour-edit-allowed.pipe';
import {PlanningFilterComponent} from './planning-filter/planning-filter.component';
import {CdkMenuModule} from '@angular/cdk/menu';
import {SplitPipe} from './pipes/split.pipe';
import {CanDeactivateForm} from './can-deactivate-form';
import {LogoutComponent} from './logout/logout.component';
import {DatetimeEqualsPipe} from './pipes/datetime-equals.pipe';
import {SimpleTinyComponent} from './simple-tiny/simple-tiny.component';
import {LicensePlatePipe} from './license-plate.pipe';
import {TransportAddressComponent} from './transport-planning/transport-address/transport-address.component';
import {LinkLoginComponent} from './auth/link-login/link-login.component';
import {CustomErrorHandlerService} from './custom-error-handler-service';
import {LoggerService} from './services/logger.service';
import {EmployeePlanningComponent} from './employee-planning/employee-planning.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {IsTraveltimePipe} from './check-hours/is-traveltime.pipe';
import {NotEditablePipe} from './check-hours/not-editable.pipe';
import {DayCheckRealisationsComponent} from './day-check-realisations/day-check-realisations.component';
import {HourtypePipe} from './pipes/hourtype.pipe';
import {AddRealisationDialogComponent} from './check-hours/add-realisation-dialog/add-realisation-dialog.component';
import {SearchPipe} from './pipes/search';
import {MinutesInputComponent} from './minutes-input.component';
import {SettlementsSendComponent} from './settlements-send/settlements-send.component';
import {SettlementsService} from './services/settlements.service';
import {EditSettlementDialogComponent} from './settlements-send/edit-settlement-dialog/edit-settlement-dialog.component';
import {AddProjectDialogComponent} from './employee-planning/add-project-dialog/add-project-dialog.component';
import {TransportInUseComponent} from './transport-planning/transport-in-use/transport-in-use.component';
import {IsWorktimePipe} from './check-hours/is-worktime.pipe';
import {CheckHoursFilterPipe} from './check-hours/check-hours-filter.pipe';
import {ProjectDayReportComponent} from './project-day-report/project-day-report.component';
import {SettlementComponent} from './settlement/settlement.component';
import {EntitiesRowsPipe} from './pipes/transportplanning-entities-rows.pipe';
import {IsOtherDatePipe} from './pipes/other-date.pipe';
import {ProjectWeekReportConfigComponent} from './project-week-report-dialog/project-week-report-config.component';
import {ProjectWeekReportComponent} from './project-week-report-dialog/project-week-report/project-week-report.component';
import {HasLicensePlatePipe} from './has-license-plate.pipe';
import {ValueInArrayPipe} from './pipes/value-in-array.pipe';
import {DatetimeBeforePipe} from './pipes/datetime-before.pipe';
import {ProjectDayHasAnyTeamPipe} from './employee-planning/project-day-has-any-team.pipe';
import {ReplaceIfPipe} from './replace-if.pipe';
import {AsphaltUsedListComponent} from './project-day-report/asphalt-used-list/asphalt-used-list.component';
import {AsphaltUsedListItemComponent} from './project-day-report/asphalt-used-list/asphalt-list-item/asphalt-used-list-item.component';
import {AsphaltApplicationPipe} from './pipes/asphalt-application.pipe';
import {EmployeeIsNightPipe} from './employee-planning/employee-is-night.pipe';
import {EntityIsNightPipe} from './employee-planning/entity-is-night.pipe';
import {CheckHoursFilterAnyPipe} from './check-hours/check-hours-filter-any.pipe';
import {EditRealisationProjectDialogComponent} from './check-hours/edit-realisation-project-dialog/edit-realisation-project-dialog.component';
import {HourEditTimeframePipe} from './hour-registration/hour-edit-timeframe.pipe';
import {DoesNotMatchDriverPipe} from './check-hours/does-not-match-driver.pipe';
import {HistoryDialogComponent} from './hour-registration/history-dialog/history-dialog.component';
import {TinyInsertImageComponent} from './simple-tiny/tiny-insert-image/tiny-insert-image.component';
import {CodaltUploadImageComponent} from './codalt-upload-image/codalt-upload-image.component';
import {CodaltImageComponent} from './codalt-image/codalt-image.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {SumPipe} from './pipes/sum.pipe';
import {FilterPipe} from './pipes/filter.pipe';
import {ProjectWeekReportHoursComponent} from './project-week-report-dialog/project-week-report/project-week-report-hours/project-week-report-hours.component';
import {LeaveComponent} from './leave/leave.component';
import {LeaveEditComponent} from './leave/leave-edit/leave-edit.component';
import {EditWeekCommentDialogComponent} from './project-week-report-dialog/project-week-report/edit-week-comment-dialog/edit-week-comment-dialog.component';
import {
    WeekCommentHistoryDialogComponent
} from './project-week-report-dialog/project-week-report/week-comment-history-dialog/week-comment-history-dialog.component';
import {IsTodayPipe} from './pipes/is-today.pipe';
import {TransportPlanningRowComponent} from './transport-planning/transport-planning-row/transport-planning-row.component';
import {ExcludedKeyPipe} from './leave/leave-edit/excluded-key.pipe';
import {ViewPdfDialogComponent} from './view-pdf-dialog/view-pdf-dialog.component';
import {SafeResourceUrlPipe} from './pipes/safe-resource-url.pipe';
import {ToolboxReadDialogComponent} from './toolbox-read-dialog/toolbox-read-dialog.component';
import {SettlementSendAllComponent} from './settlement-send-all/settlement-send-all.component';
import {RealisationAfasProjectIdPipe} from './pipes/realisation-afas-project-id.pipe';
import {WeekCheckRealisationsComponent} from './week-check-realisations/week-check-realisations.component';
import {WeekreportPdfOptionsDialogComponent} from './project-week-report-dialog/weekreport-pdf-options-dialog/weekreport-pdf-options-dialog.component';
import {AddLeaveNameDialogComponent} from './employee-planning/add-leave-name-dialog/add-leave-name-dialog.component';
import {EmployeeDayInfoDialogComponent} from './employee-planning/employee-day-info-dialog/employee-day-info-dialog.component';
import {TransportPlanningTextComponent} from './transport-planning/transport-planning-text/transport-planning-text.component';
import {SimpleTransportTinyComponent} from './transport-planning/transport-planning-text/simple-transport-tiny/simple-transport-tiny.component';
import {ToolboxComponent} from './toolbox/toolbox.component';
import {ToolboxEditComponent} from './toolbox/toolbox-edit/toolbox-edit.component';
import {CodaltUploadFileComponent} from './codalt-upload-file/codalt-upload-file.component';
import {HourtypeIconPipe} from './pipes/hourtype-icon.pipe';
import {GroupDayChangesPipe} from './project-week-report-dialog/project-week-report/project-week-report-hours/group-day-changes.pipe';
import {ProjectsComponent} from './projects/projects.component';
import {ProjectEditComponent} from './projects/project-edit/project-edit.component';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {HireNameComponent} from './transport-planning/hire-name/hire-name.component';
import {NotApprovablePipe} from './check-hours/not-approvable.pipe';
import {VkmPlanningComponent} from './vkm/vkm-planning/vkm-planning.component';
import {MatTabsModule} from '@angular/material/tabs';
import {SortTabsPipe} from './vkm/vkm-planning/sort-tabs.pipe';
import {NameTabsPipe} from './vkm/vkm-planning/name-tabs.pipe';
import {NotSettledCountPipe} from './vkm/vkm-planning/not-settled-count.pipe';
import {TeamHasPlanningPipe} from './vkm/vkm-projects/team-has-planning.pipe';
import {VkmDayPlanningComponent} from './vkm/vkm-projects/vkm-day-planning.component';
import {VkmWorkordersComponent} from './vkm/vkm-workorders/vkm-workorders.component';
import { CopyRealisationFromOtherDayComponent } from './check-hours/copy-realisation-from-other-day/copy-realisation-from-other-day.component';


registerLocaleData(localeNL, 'nl');

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
    showDelay: 750,
    hideDelay: 0,
    touchendHideDelay: 2000
};

@NgModule({
    declarations: [
        AppComponent,
        SettingsMenuComponent,
        RequestRestorePasswordComponent,
        RestorePasswordComponent,
        UserEditComponent,
        UsersOverviewComponent,
        ConfirmDialogComponent,
        EntitiesOverviewComponent,
        EntityDetailComponent,
        PlanningOverviewComponent,
        PlanningDetailDialogComponent,
        CutterListComponent,
        WipetruckListComponent,
        DecimalInputDirective,
        HeaderComponent,
        AsphaltListComponent,
        AsphaltComponent,
        ImportAsphaltListDialogComponent,
        DayComponent,
        EntityComponent,
        TrucksComponent,
        TonsComponent,
        DateIndicatorComponent,
        AsphaltOrderListComponent,
        SetPlanningFixedDialogComponent,
        AsphaltExportDialogComponent,
        PlanningListComponent,
        FieldErrorComponent,
        TooltipPipe,
        ByIdPipe,
        IsNightPipe,
        MaterialPiecePipe,
        IsSubsequentPipe,
        Nl2brPipe,
        PlanningEntitiesToStringPipe,
        AsphaltListItemComponent,
        PlanningExportDialogComponent,
        LoadingDialogComponent,
        PlanningDetailContainerComponent,
        PlanningDetailPrintComponent,
        HirePeopleSelectorDialogComponent,
        ProjectmanagerReportDialogComponent,
        HirePeopleDialogComponent,
        HirePeopleWeekComponent,
        HiringCompaniesDialogComponent,
        SettlementsSendComponent,
        HiringCompanyEditDialogComponent,
        EmployeesDialogComponent,
        TransportPlanningComponent,
        TransportInUseComponent,
        MainPlanningPipe,
        ProfileComponent,
        DatetimeInputComponent,
        WhereInPipe,
        RealisationAfasProjectIdPipe,
        HourEditDialogComponent,
        HourRegistrationComponent,
        WeekScheduleComponent,
        DayDetailComponent,
        CheckHoursComponent,
        MinutesPipe,
        SumPipe,
        FilterPipe,
        ProjectSelectionDialogComponent,
        PeriodSettlementComponent,
        TransportplanningColsPipe,
        EntityUnavailablePipe,
        HiredCompaniesDialogComponent,
        DayCheckRealisationsComponent,
        WeekCheckRealisationsComponent,
        AddressDialogComponent,
        AddressIconComponent,
        EquipmentDialogComponent,
        EntityTypePipe,
        CodaltComponent,
        MultiplePipe,
        DoesNotMatchDriverPipe,
        AllApprovedPipe,
        HasUserGroupPipe,
        HasUserGroupPipe,
        CommentDialogComponent,
        HistoryDialogComponent,
        OwnHourEditAllowedPipe,
        EditSettlementDialogComponent,
        PlanningFilterComponent,
        SplitPipe,
        LogoutComponent,
        DatetimeEqualsPipe,
        SimpleTinyComponent,
        SimpleTransportTinyComponent,
        DatetimeEqualsPipe,
        LinkLoginComponent,
        LicensePlatePipe,
        TransportAddressComponent,
        EmployeePlanningComponent,
        NotEditablePipe,
        NotApprovablePipe,
        IsTraveltimePipe,
        IsWorktimePipe,
        HourtypePipe,
        AddRealisationDialogComponent,
        EditRealisationProjectDialogComponent,
        SearchPipe,
        MinutesInputComponent,
        AddProjectDialogComponent,
        CheckHoursFilterPipe,
        ProjectDayReportComponent,
        SettlementComponent,
        AsphaltUsedListComponent,
        AsphaltUsedListItemComponent,
        EntitiesRowsPipe,
        IsOtherDatePipe,
        ProjectWeekReportConfigComponent,
        ProjectWeekReportComponent,
        HasLicensePlatePipe,
        ValueInArrayPipe,
        ProjectDayHasAnyTeamPipe,
        DatetimeBeforePipe,
        ReplaceIfPipe,
        AsphaltApplicationPipe,
        EmployeeIsNightPipe,
        EntityIsNightPipe,
        CheckHoursFilterAnyPipe,
        HourEditTimeframePipe,
        TinyInsertImageComponent,
        CodaltUploadImageComponent,
        CodaltImageComponent,
        ProjectWeekReportHoursComponent,
        LeaveComponent,
        LeaveEditComponent,
        IsTodayPipe,
        TransportPlanningRowComponent,
        EditWeekCommentDialogComponent,
        WeekCommentHistoryDialogComponent,
        ExcludedKeyPipe,
        ViewPdfDialogComponent,
        SafeResourceUrlPipe,
        ToolboxReadDialogComponent,
        WeekreportPdfOptionsDialogComponent,
        ToolboxReadDialogComponent,
        SettlementSendAllComponent,
        AddLeaveNameDialogComponent,
        EmployeeDayInfoDialogComponent,
        TransportPlanningTextComponent,
        ToolboxComponent,
        ToolboxEditComponent,
        CodaltUploadFileComponent,
        HourtypeIconPipe,
        GroupDayChangesPipe,
        ProjectsComponent,
        ProjectEditComponent,
        SafeHtmlPipe,
        HireNameComponent,
        VkmPlanningComponent,
        SortTabsPipe,
        NameTabsPipe,
        NotSettledCountPipe,
        VkmWorkordersComponent,
        VkmDayPlanningComponent,
        TeamHasPlanningPipe,
        CopyRealisationFromOtherDayComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatSelectModule,
        MatDatepickerModule,
        NgSelectModule,
        MatNativeDateModule,
        MatRadioModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatDividerModule,
        MatTooltipModule,
        MatSlideToggleModule,
        DragDropModule,
        MatIconModule,
        MatListModule,
        MatCheckboxModule,
        MatRippleModule,
        MatMenuModule,
        ClipboardModule,
        CdkMenuModule,
        MatSnackBarModule,
        AngularSvgIconModule.forRoot(),
        MatTabsModule
    ],
    providers: [
        ApiService,
        AuthService,
        UserService,
        SettlementsService,
        AsphaltService,
        WebsocketService,
        DialogService,
        PlanningDetailDialogService,
        ConfirmDialogService,
        LocationService,
        DatePipe,
        IsSubsequentPipe,
        PlanningEntitiesToStringPipe,
        CdkDropList,
        CanDeactivateForm,
        LoggerService,
        {provide: ErrorHandler, useClass: CustomErrorHandlerService},
        {provide: LOCALE_ID, useValue: 'nl'},
        {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults},
        {provide: HTTP_INTERCEPTORS, useClass: CodaltInterceptor, multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
