<div class="dialog-container">
    <h1 class="condensed dialog-title">Materieel van voorgaande dagen</h1>
    <div class="dialog-content">
        <div *ngIf="!realisationList?.length && !!realisationList">
            Er zijn geen materieelstukken die overgenomen kunnen worden van voorgaande dagen.
        </div>
        <div *ngIf="!realisationList?.length && !realisationList">
            Laden...
        </div>
        <mat-selection-list [formControl]="fcEntities">
            <mat-list-option [value]="entity.entity.id" *ngFor="let entity of realisationList">
                <div mat-line>{{entity.entity.name}}</div>
                <div mat-line>
                    <i>Gebruikt op: </i>
                    <ng-container *ngFor="let r of entity.realisations; let last = last">
                        {{r.bookdate | date : 'EEE d MMM'}}{{last ? '' : ', '}}
                    </ng-container>
                </div>
            </mat-list-option>
        </mat-selection-list>
    </div>
    <div class="d-flex flex-wrap pt-3">
        <button mat-button mat-dialog-close>Annuleren</button>
        <div class="spacer"></div>
        <button mat-raised-button (click)="take()" cdkFocusInitial color="primary">Overnemen</button>
    </div>
</div>
